import React from 'react';
import * as Style from './ticketMapHeader.module.scss';

export interface TicketMapHeaderProps {
  onBack: () => void;
}

export const TicketMapHeader: React.FC<TicketMapHeaderProps> = ({ onBack }) => (
  <button type="button" className={Style.backButton} onClick={onBack}>
    <span className={Style.chevronLeft} />
    戻る
  </button>
);
