import React from 'react';
import '__generated__/gatsby-types';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Prefacture } from 'const/prefacture';
import { prefactureBounds } from 'const/bounds';

import * as Style from './ticketMap.module.scss';

export interface LatLngBoundsLiteral {
  /**
   * East longitude in degrees. Values outside the range [-180, 180] will be
   * wrapped to the range [-180, 180). For example, a value of -190 will be
   * converted to 170. A value of 190 will be converted to -170. This reflects
   * the fact that longitudes wrap around the globe.
   */
  east: number;
  /**
   * North latitude in degrees. Values will be clamped to the range [-90, 90].
   * This means that if the value specified is less than -90, it will be set
   * to -90. And if the value is greater than 90, it will be set to 90.
   */
  north: number;
  /**
   * South latitude in degrees. Values will be clamped to the range [-90, 90].
   * This means that if the value specified is less than -90, it will be set
   * to -90. And if the value is greater than 90, it will be set to 90.
   */
  south: number;
  /**
   * West longitude in degrees. Values outside the range [-180, 180] will be
   * wrapped to the range [-180, 180). For example, a value of -190 will be
   * converted to 170. A value of 190 will be converted to -170. This reflects
   * the fact that longitudes wrap around the globe.
   */
  west: number;
}

export type TicketMapProps = React.PropsWithChildren<{
  useGPS: boolean;
  boundsLiteral?: LatLngBoundsLiteral;
  boundsLine: boolean;
}>;

export const TicketMap: React.FC<TicketMapProps> = ({
  children,
  useGPS,
  boundsLiteral,
  boundsLine,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_MAP_API_KEY as string,
  });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [location, setLocation] =
    React.useState<{ lat: number; lng: number }>();

  if (useGPS) {
    const onFetchCurrentPosition = (pos: GeolocationPosition) => {
      if (!location) {
        setLocation({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      }
    };
    navigator.geolocation.getCurrentPosition(onFetchCurrentPosition);
  }

  React.useEffect(() => {
    if (map && location) {
      map.setCenter(location);
    }
  }, [map, location]);

  React.useEffect(() => {
    if (map && boundsLine && boundsLiteral) {
      (Object.keys(prefactureBounds) as Prefacture[]).forEach((key) => {
        const b = prefactureBounds[key].bounds;
        const boundsPath = new google.maps.Polyline({
          path: [
            { lat: b.northeast.lat, lng: b.northeast.lng },
            { lat: b.southwest.lat, lng: b.northeast.lng },
            { lat: b.southwest.lat, lng: b.southwest.lng },
            { lat: b.northeast.lat, lng: b.southwest.lng },
            { lat: b.northeast.lat, lng: b.northeast.lng },
          ],
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        boundsPath.setMap(map);
      });

      const ply = new google.maps.Polyline({
        path: [
          { lat: boundsLiteral.north, lng: boundsLiteral.east },
          { lat: boundsLiteral.south, lng: boundsLiteral.east },
          { lat: boundsLiteral.south, lng: boundsLiteral.west },
          { lat: boundsLiteral.north, lng: boundsLiteral.west },
          { lat: boundsLiteral.north, lng: boundsLiteral.east },
        ],
        geodesic: true,
        strokeColor: '#0000FF',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      ply.setMap(map);
    }
  }, [map, boundsLine, boundsLiteral]);

  const onLoad = React.useCallback(
    (m: google.maps.Map) => {
      if (boundsLiteral) {
        const latLngBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(boundsLiteral.south, boundsLiteral.east),
          new google.maps.LatLng(boundsLiteral.north, boundsLiteral.west),
        );
        m.fitBounds(latLngBounds);
      }
      setMap(m);
    },
    [boundsLiteral],
  );

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const JAPAN_BOUNDS = {
    north: 47.9215259261852,
    south: 15.36920848467935,
    west: 120.00793798031395,
    east: 153.89075118287516,
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerClassName={Style.map}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
          restriction: {
            latLngBounds: JAPAN_BOUNDS,
            strictBounds: true,
          },
        }}
      >
        {children}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};
