import React from 'react';
import '__generated__/gatsby-types';
import { Marker } from '@react-google-maps/api';
import { MarkerInfo } from 'state/makerInfo';

export interface TicketMapMarkerProps {
  info?: MarkerInfo;
  setInformation: React.Dispatch<React.SetStateAction<MarkerInfo | undefined>>;
}

export const TicketMapMarker: React.FC<TicketMapMarkerProps> = ({
  info,
  setInformation,
}) => {
  let position;
  if (info && info.location && info.location.lat && info.location.lon) {
    position = { lat: info.location.lat, lng: info.location.lon };
  } else {
    return <></>;
  }

  return (
    <Marker
      position={position}
      onClick={() => {
        setInformation({ ...info });
      }}
    />
  );
};
