import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { Layout } from 'components/layout/layout';
import { Seo } from 'components/seo';
import { TicketMapHeader } from 'components/ticketMapHeader/ticketMapHeader';
import { TicketMap, LatLngBoundsLiteral } from 'components/ticketMap/ticketMap';
import { TicketMapMarker } from 'components/ticketMapMarker';
import { MarkerDetail } from 'components/markerDetail/markerDetail';
import { useLocation } from '@reach/router';
import { MarkerInfo } from 'state/makerInfo';
import { Prefacture } from 'const/prefacture';
import { prefactureBounds } from 'const/bounds';

import * as queryString from 'query-string';
import '__generated__/gatsby-types';
import 'styles/default.scss';

const MapPage: React.FC = () => {
  const location = useLocation();
  const [info, setInformation] = React.useState<MarkerInfo>();

  const data = useStaticQuery<GatsbyTypes.MapQuery>(graphql`
    query Map {
      count: allContentfulSellerLocation {
        totalCount
      }
      allContentfulSellerLocation(filter: { node_locale: { eq: "ja-JP" } }) {
        edges {
          node {
            group {
              name
            }
            name
            postalCode
            prefecture
            city
            address1
            address2
            locationText
            location {
              lon
              lat
            }
            gifteeStoreId
          }
        }
      }
    }
  `);

  // https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
  React.useEffect(() => {
    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) {
        return;
      }
      vw = window.innerWidth;
      setFillHeight();
    });
    setFillHeight();
  }, []);

  const parsed = queryString.parse(location?.search);
  const useGPS = parsed?.useGPS === 'true';
  const prefacture = parsed?.prefacture as Prefacture | undefined;
  const boundsLine = parsed?.boundsLine === 'true';

  let boundsLiteral: LatLngBoundsLiteral | undefined;
  if (!useGPS && prefacture && prefactureBounds[prefacture]) {
    const b = prefactureBounds[prefacture];
    boundsLiteral = {
      north: b.bounds.northeast.lat,
      east: b.bounds.northeast.lng,
      south: b.bounds.southwest.lat,
      west: b.bounds.southwest.lng,
    };
  }

  const makers = data.allContentfulSellerLocation.edges
    .map((e) => e.node)
    .map((n) => {
      const address = `${n.prefecture} ${n.city} ${n.address1} ${n.address2}`;
      let link: string | undefined;
      if (n.locationText) {
        link = `https://www.google.co.jp/maps/search/${n.locationText}`;
      } else {
        link = undefined;
      }

      return (
        <TicketMapMarker
          key={n.gifteeStoreId}
          info={{
            address,
            link,
            name: n.name as string,
            postalCode: n.postalCode as string,
            storeId: n.gifteeStoreId as string,
            location: n.location as MarkerInfo['location'],
          }}
          setInformation={setInformation}
        />
      );
    });

  const onClose = () => {
    setInformation(undefined);
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Seo title={useGPS ? `現在地周辺のお店` : `都道府県内のお店`} />
      <TicketMapHeader onBack={onBack} />
      <TicketMap
        boundsLine={boundsLine}
        useGPS={useGPS}
        boundsLiteral={boundsLiteral}
      >
        {makers}
      </TicketMap>
      <MarkerDetail info={info} onClose={onClose} />
    </Layout>
  );
};

export default MapPage;
