import React from 'react';
import { MarkerInfo } from 'state/makerInfo';

import * as Style from './markerDetail.module.scss';

export interface MarkerDetailProps {
  info?: MarkerInfo;
  onClose: () => void;
}

export const MarkerDetail: React.FC<MarkerDetailProps> = ({ info, onClose }) =>
  info ? (
    <div className={Style.markerDetail}>
      <div className={Style.close} onClick={onClose} aria-hidden="true" />
      <div className={Style.name}>{info.name}</div>
      <div className={Style.postalCode}>〒{info.postalCode}</div>
      <div className={Style.address}>{info.address}</div>

      {info.link ? (
        <a
          className={Style.button}
          href={info.link}
          target="_blank"
          rel="noreferrer"
        >
          詳細を見る
        </a>
      ) : null}
    </div>
  ) : (
    <></>
  );
